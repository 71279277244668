import Vue from 'vue';
import VueRouter from 'vue-router';
import DefaultLayout from '../layouts/DefaultLayout.vue';
import DashboardLayout from '../layouts/DashboardLayout';
import isAuthenticatedGuard from '@/router/auth-guard';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		redirect: '/login',
		name: 'ToLogin',
	},
	{
		path: '/',
		component: DefaultLayout,
		name: 'DefaultLayout',
		children: [
			{
				path: '/login',
				name: 'Login',
				component: () =>
					import(/* webpackChunkName: "login" */ '../views/Login.vue'),
			},
			{
				path: '/login/resetpassword',
				name: 'ResetPassword',
				component: () =>
					import(
						/* webpackChunkName: "ResetPassword" */ '../views/account/ResetPassword.vue'
					),
			},
			{
				path: '/recover/:token',
				name: 'RecoverPassword',
				component: () =>
					import(
						/* webpackChunkName: "RecoverPassword" */ '../views/account/RecoverPassword.vue'
					),
			},
		],
	},
	{
		path: '/',
		component: DashboardLayout,
		name: 'Home',
		children: [
			{
				path: '/dashboard',
				name: 'Dashboard',
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
			},
			{
				path: '/dashboard/candidate/search',
				name: 'CandidateSearch',
				meta: { moduleName: 'Candidatos', pageName: 'listaCandidatos' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "CandidateSearch" */ '@/views/dashboard/candidate/search/searchCandidatesView.vue'
					),
			},{
				path: '/dashboard/candidate/activity',
				name: 'ActivityLogs',
				meta: { moduleName: 'Candidatos', pageName: 'listaActividad' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "CandidateSearch" */ '@/views/dashboard/candidate/activity/activityLogsView.vue'
					),
			},
			{
				path: '/dashboard/candidate/upload',
				name: 'CandidateUpload',
				meta: { moduleName: 'Candidatos', pageName: 'cargarCandidatos' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "CandidateUpload" */ '@/views/dashboard/candidate/upload/uploadCandidatesView.vue'
					),
			},
			{
				path: '/dashboard/candidate/statistics',
				name: 'CandidateStats',
				meta: { moduleName: 'Candidatos', pageName: 'listaGraficos' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "CandidateUpload" */ '@/views/dashboard/candidate/statistics/candidateStatsView.vue'
          ),
			},
      {
				path: '/dashboard/candidate/template',
				name: 'EditTemplate',
				meta: { moduleName: 'Candidatos', pageName: 'editarPlantilla' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "EditTemplate" */ '@/views/dashboard/candidate/template/editTemplateView.vue'
					),
			},
			{
				path: '/dashboard/file/read',
				name: 'ReadFile',
				meta: { moduleName: 'Candidatos' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "ReadFile" */ '@/views/dashboard/file/read/FileReadView.vue'
					),
			},

			// {
			// 	path: '/dashboard/contract/read/:documentId',
			// 	name: 'documentShow',
			// 	meta: { moduleName: 'Contrato', pageName: 'mostrarContrato' },
			// 	beforeEnter: isAuthenticatedGuard,
			// 	component: () =>
			// 		import(
			// 			/* webpackChunkName: "ReadContract" */ '@/views/dashboard/contract/read/ReadContractView.vue'
			// 		),
			// },
			{
				path: '/dashboard/user/permissions',
				name: 'UserPermissions',
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "UserPermissions" */ '@/views/dashboard/user/permissions/permissions.vue'
					),
			},
			{
				path: '/401',
				name: 'NotAuthorized',
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(/* webpackChunkName: "NotAuthorized" */ '../views/401.vue'),
			},
			{
				path: '/:pathMatch(.*)*',
				name: 'NotFound',
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(/* webpackChunkName: "NotFound" */ '../views/404.vue'),
			},
		],
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

export default router;
