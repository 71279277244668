//import * as candidateService from './service/cantidate.service';

const state = {
	// documentTypes: [],
};
const mutations = {
	// SET_DOCUMENT_TYPES(state, payload) {
	// 	state.documentTypes = payload || null;
	// },
};

const actions = {
	// async list({ commit }, data) {
	// 	try {
	// 		commit('SET_DOCUMENT_TYPES', null);
	// 		const response = await candidateService.listDocumentType(data);
	// 		commit('SET_DOCUMENT_TYPES', response || null);
	// 		return { ok: true, response };
	// 	} catch (error) {
	// 		return { ok: false, error: error?.response || 'Error en el servicio' };
	// 	}
	// },

	// async create(context, data) {
	// 	try {
	// 		const response = await candidateService.create(data);
	// 		return { ok: true, response };
	// 	} catch (error) {
	// 		return { ok: false, error: error?.response || 'Error en el servicio' };
	// 	}
	// },

	// cleanNotifications({ commit }) {
	// 	commit('SET_DOCUMENT_TYPES', []);
	// },
};

const getters = {};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
