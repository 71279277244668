import store from '@/store';

const isAuthenticatedGuard = async (to, from, next) => {
	const { ok } = await store.dispatch('auth/checkAuthentication');
	const { activities /*flag_password_reset*/ } = await store.state.auth;
	const metas = to.matched[1].meta;

	if (!ok) {
		next({ name: 'Login' });
		return
	}

	if (!metas.moduleName || !metas.pageName) {
		next();
		return;
	}

	const foundActivity = activities.find((activity) => {
		return (
			activity.module_name == metas.moduleName &&
			activity.page_name == metas.pageName
		);
	});

	if (!foundActivity) {
		next({ name: 'NotAuthorized' });
		return;
	}

	next();
};

export default isAuthenticatedGuard;
