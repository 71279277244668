import Vue from 'vue';
import Vuex from 'vuex';
// MODULES
import security from './modules/security';
import auth from './modules/auth';
import user from './modules/user';
import candidate from './modules/candidate';

Vue.use(Vuex);

export default new Vuex.Store({
	// TODO: strict: true,
	state: {},
	mutations: {},
	actions: {},
	modules: {
		security,
		auth,
		user,
		candidate,
	},
});
