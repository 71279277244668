import * as authService from './service/auth.service';

const state = {
	status: 'authenticating', // 'authenticated','not-authenticated', 'authenticating'
	user: null,
	token: null,
	modules: [],
	activities: [],
	roles: [],
	flag_password_reset: false,
};

const mutations = {
	LOGIN_USER(state, { user, token }) {
		if (token) {
			localStorage.setItem('token', token);
			state.token = token;
		}

		state.user = user;
		state.status = 'authenticated';
	},

	LOGOUT(state) {
		state.user = null;
		state.token = null;
		state.status = 'not-authenticated';

		localStorage.removeItem('token');
		localStorage.removeItem('company_id');
	},

	SET_FLAG_PASSWORD_RESET(state, payload) {
		state.flag_password_reset = payload;
	},

	SET_MODULES(state, { user, company_id }) {
		const companies = user?.companies || [];
		let company = null;
		let modules = [];
		let access = [];
		if (Array.isArray(companies)) {
			company = companies.find((company) => company.id == company_id);
		}
		if (Array.isArray(company?.profiles)) {
			company.profiles.forEach((profiles) => {
				profiles.modules.forEach((module) => {
					modules.push(module);
					module.pages.forEach((page) => {
						page.activities.forEach((activity) => {
							access.push({
								module_name: module?.name,
								page_name: page?.name,
								activity_name: activity?.name,
							});
						});
					});
				});
			});
		}
		if (Array.isArray(company?.profiles)) {
			let roles = [];
			company.profiles.forEach((profile) => {
				roles.push({ ...profile.role });
			});
			state.roles = roles;
		}
		state.modules = modules;
		state.activities = access;
	},
};

const actions = {
	async setSession({ commit }, data = null) {
		await commit('LOGIN_USER', { user: data.email, token: data.token });
		return { ok: true, message: null };
	},
	async signInUser({ commit }, user = null) {
		try {
			const { email, accessToken } = await authService.login(user);
			const userDetail = { email };

			await commit('LOGIN_USER', { user: userDetail, token: accessToken });
			return { ok: true, message: null };
		} catch (error) {
			return { ok: false, message: error?.response?.data?.message };
		}
	},

	async checkAuthentication({ commit }) {
		const token = localStorage.getItem('token');
		let company_id = localStorage.getItem('company_id');

		if (!token) {
			commit('LOGOUT');
			return { ok: false, message: 'No hay token' };
		}

		try {
			const { user } = await authService.getProfile();

			if (Array.isArray(user?.companies)) {
				const found = user.companies.find(
					(company) => company.id == parseInt(company_id)
				);
				if (!found) {
					localStorage.setItem('company_id', user.companies[0]?.id);
					company_id = user.companies[0]?.id;
				}
			}

			commit('LOGIN_USER', { user, token });
			commit('SET_MODULES', { user, company_id });
			commit('SET_FLAG_PASSWORD_RESET', user?.flag_password_reset || false);

			return { ok: true };
		} catch (error) {
			commit('LOGOUT');
			return { ok: false, message: error.response?.data?.message };
		}
	},

	async passwordRecovery(context, payload = null) {
		try {
			const data = await authService.passwordRecovery(payload);
			return { ok: true, message: data?.message };
		} catch (error) {
			return { ok: false, message: error?.response?.data?.message };
		}
	},

	async passwordRecoveryToken(context, payload = null) {
		try {
			await authService.passwordRecoveryToken(payload);
			return { ok: true, message: true };
		} catch (error) {
			return { ok: false, message: error?.response?.data?.message };
		}
	},

	async passwordUpdateRecovery(context, payload = null) {
		try {
			const data = await authService.passwordUpdateRecovery(payload);
			return { ok: true, message: data?.message };
		} catch (error) {
			return { ok: false, message: error?.response?.data?.message };
		}
	},

	//passwordRecovery

	logout({ commit }) {
		commit('LOGOUT');
	},

	checkAccess() {},
};

const getters = {
	getCompanies: (state) => {
		let companies = state?.user?.companies || [];
		if (Array.isArray(companies)) {
			companies = companies.map((company) => {
				return { id: company.id, name: company.name };
			});
		}
		return companies;
	},

	hasActivity: (state) => (module, page, activity) => {
		const activities = state?.activities || [];
		const found = activities.some(
			(item) =>
				item.module_name == module &&
				item.page_name == page &&
				item.activity_name == activity
		);
		return found;
	},

	hasRole: (state) => (rol) => {
		const roles = state?.roles || [];
		const found = roles.some((item) => item?.name == rol);
		return found;
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
