<template>
	<v-snackbar v-model="snackbar" :color="status" :timeout="timeout">
		<div v-html="text"></div>
		<template v-slot:action="{ attrs }">
			<v-btn text v-bind="attrs" @click="snackbar = false"> Cerrar </v-btn>
		</template>
	</v-snackbar>
</template>
<script>
import { $EventBus } from '@/main';
export default {
	data: () => ({
		snackbar: false,
		text: ``,
		status: '',
		timeout: 5000,
	}),
	mounted: function () {
		$EventBus.$on('showSnack', (status, message, timeout = 5000) => {
			this.snackbar = true;
			this.status = status;
			this.text = message;
			this.timeout = timeout;
		});
	},
};
</script>
